<template>
  <div class="error">
    <p class="text form__text">{{ title }}</p>
    <div class="value" :style="value_style">{{ new_value }} {{ addition }}</div>
    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="new_value"
      class="form__email input range"
      :style="range_style"
      @input="changeRange"
    />
  </div>
</template>

<script>
export default {
  name: "Range",
  props: [
    "title",
    "addition",
    "placeholder",
    "min",
    "max",
    "step",
    "field",
    "value",
  ],
  data() {
    const already_value =
      this.value && (+this.value <= +this.max) ? this.value : this.min;
    return {
      new_value: already_value,
      value_style: {
        left: "",
      },
      range_style: {
        background: "",
      },
    };
  },
  mounted() {
    this.changeRange();
  },
  methods: {
    changeRange(e) {
      this.new_value = e ? e.target.value : this.new_value;
      this.range_style.background = `-webkit-linear-gradient(left, #01a7fd 0%, #01a7fd ${this.new_value}%, #cfcfcf ${this.new_value}%, #cfcfcf 100%)`;
      this.value_style.left = `${3.15 * this.new_value - 24}px`;

      this.$emit("value-input", {
        field: this.field,
        value: this.new_value,
      });
    },
  },
};
</script>

<style>
</style>