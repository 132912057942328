<template>
  <div class="notifications__form-flex">
    <div>
      <label
        :for="id"
        class="notifications__label"
        :class="{
          'notifications__label-not-active': !new_value && !need_to_disable,
        }"
        @click="changeValue"
      >
        <picture>
          <source
            srcset="/img/Settings/nike-for-checkbox.svg"
            type="image/webp"
          />
          <img src="/img/Settings/nike-for-checkbox.svg" alt="nike" />
        </picture>
      </label>
      <input
        :id="id"
        type="checkbox"
        :checked="new_value"
        class="notifications__form-checkbox"
        :disabled="need_to_disable"
      />
    </div>
    <p class="notifications__form-text" v-html="title">
    </p>
  </div>
</template>

<script>
import { API } from "../../helpers/api";

export default {
  props: ["title", "id", "value", "field", "is_ajax", "ajax_endpoint"],
  name: "Checkbox",
  data() {
    const already_value = this.value ? this.value : false;
    return {
      new_value: already_value,
      need_to_disable: false
    };
  },
  methods: {
    async changeValue() {
      this.new_value = !this.new_value;

      if (this.is_ajax) {
        this.need_to_disable = true;
        try {
          const response = await API.send(
            this.ajax_endpoint,
            {
              settings: JSON.stringify({
                [this.field]: this.new_value,
              }),
            },
            "POST"
          );

          if (response.status !== 0) {
            alert(response.user_message);
            return;
          }
        } catch (e) {
          alert("При обновлении настроек произошла ошибка");
          console.error(e);
        } finally {
          this.need_to_disable = false;
        }
      }

      this.$emit("value-input", {
        field: this.field,
        value: this.new_value,
      });
    },
  },
};
</script>