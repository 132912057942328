<template>
  <main class="marketplace">
    <div class="container-1440">
      <div class="marketplace__content marketplace__content-active">
        <section class="marketplace__content-left-column">
          <div class="marketplace__header">
            <div class="marketplace__title">
              <h1 class="title">Маркетплейс</h1>
            </div>
            <div class="marketplace__toggle">
              <p
                class="marketplace__toggle-search"
                :class="{
                  'marketplace__toggle-active': tasksActive,
                }"
                @click="selectTab('tasks')"
              >
                Найти задание
              </p>
              <p
                class="marketplace__toggle-performers"
                :class="{
                  'marketplace__toggle-active': peopleActive,
                }"
                @click="selectTab('people')"
              >
                Исполнители
              </p>
            </div>
          </div>
          <div class="marketplace__items" v-if="peopleActive">
            <People
              v-for="item in people_items"
              :key="`people-${item.id}`"
              :item="item"
            />
          </div>
          <div class="marketplace__items" v-if="tasksActive">
            <Task
              v-for="item in task_items"
              :key="`task-${item.id}`"
              :item="item"
            />
          </div>
        </section>
        <section class="marketplace__content-right-column" v-if="peopleActive">
          <div class="marketplace__search">
            <button class="marketplace__search-button" @click="goToCreation">
              <p>Создать задание +</p>
            </button>
          </div>
          <h2 class="marketplace__subtitle">Фильтр:</h2>
          <form class="tasks__form form">
            <article>
              <p class="text form__text">Специализация</p>
              <select class="form__select input">
                <option value="USA">Страховки</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Стоимость в час</p>
              <select class="form__select input">
                <option value="USA">от 30 ло 200</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Опыт</p>
              <select class="form__select input">
                <option value="USA">Более 5 лет</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Язык</p>
              <select class="form__select input">
                <option value="USA">Русский</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Рейтинг</p>
              <select class="form__select input">
                <option value="USA">от 4</option>
              </select>
            </article>
            <article>
              <Checkbox
                @value-input="saveValue"
                id="filter_by_license"
                title="Есть лицензия"
                field="filter_by_license"
              />
            </article>
            <article>
              <Checkbox
                @value-input="saveValue"
                id="filter_by_video"
                title='<picture><img src="/img/marketplace/camera.svg" alt="" /></picture>'
                field="filter_by_video"
              />
            </article>
          </form>
        </section>
        <section class="marketplace__content-right-column" v-if="tasksActive">
          <div class="marketplace__search">
            <button
              class="marketplace__search-button marketplace__search-button-img"
            >
              <picture>
                <img src="/img/marketplace/loupe-marketplace.svg" alt="" />
              </picture>
            </button>
          </div>
          <h2 class="marketplace__subtitle">Фильтр:</h2>
          <form class="tasks__form form">
            <article>
              <p class="text form__text">Специализация</p>
              <select class="form__select input">
                <option value="USA">Страховки</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Страна миграции</p>
              <select class="form__select input">
                <option value="USA">от 4</option>
              </select>
            </article>
            <article>
              <p class="text form__text">Тип заявки</p>
              <select class="form__select input">
                <option value="USA">Консультация</option>
              </select>
            </article>
            <article>
              <p class="text form__text">По стоимости</p>
              <select class="form__select input">
                <option value="USA">До 500</option>
              </select>
            </article>
          </form>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { API } from "../../helpers/api";

import People from "../../components/marketplace/People.vue";
import Task from "../../components/marketplace/Task.vue";
import Checkbox from "../../components/utils/Checkbox.vue";

export default {
  name: "MarketplaceBrowser",
  components: {
    People,
    Task,
    Checkbox,
  },
  data() {
    return {
      active_tab: "tasks",
      values: {
        filter_by_license: false,
        filter_by_video: false
      },
      people_items: [
        {
          id: 1,
          avatar: "/img/marketplace/ava-marketplace.png",
          nickname: "Golanginya",
          job: "H&M Law company",
          country: "Canada",
          stars: 3,
          languages: "Русский, английский, испанский",
          info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci consequat eget eu cursus feugiat ac. Proin molestie morbi sem augue fringilla vitae nunc. Non semper ultrices nisl tincidunt. Platea eu done`,
          min_price: 3000,
          feedback_count: 300,
          tags: [
            {
              id: 1,
              name: "Top",
            },
            {
              id: 2,
              name: "Hot",
            },
            {
              id: 3,
              name: "Closed",
            },
            {
              id: 4,
              name: "Tag",
            },
          ],
        },
      ],
      task_items: []
    };
  },
  computed: {
    tasksActive() {
      return this.active_tab === "tasks";
    },
    peopleActive() {
      return this.active_tab === "people";
    },
  },
  async mounted() {
    try {
      const response = await API.send("getTasks", {}, "GET");

      if (response.status !== 0) {
        alert(response.user_message);
        return;
      }

      this.task_items  = response.tasks;
    } catch (e) {
      alert("Произошла неизвестная ошибка. Попробуйте позже.");
      console.error(e);
    }
  },
  methods: {
    checkItems(data) {
      this.values[data.field] = data.value;
    },
    selectTab(mode) {
      this.active_tab = mode;
    },
    goToCreation() {
      this.$router.push({
        path: "/marketplace/create-task",
      });
    },
  },
};
</script>