<template>
  <article class="exercise">
    <div class="exercise__left-column">
      <div class="exercise__content">
        <h2 class="exercise__title">
          {{ item.title }}
        </h2>
        <p class="exercise__text">
          {{ item.text }}
          <!--<span class="exercise__text-more">hide</span>-->
        </p>
        <div class="exercise__info">
          <div class="exercise__country">{{ item.country }}</div>
          <div class="exercise__imgs">
            <picture v-if="item.need_videoconsult">
              <img src="/img/marketplace/camera.svg" alt="" />
            </picture>
            <picture v-if="item.need_fullassist">
              <img src="/img/marketplace/user.svg" alt="" />
            </picture>
          </div>
          <div class="exercise__price">{{ item.budget }} $</div>
        </div>
        <div class="exercise__date">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
              stroke="#cfcfcf"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 10H21"
              stroke="#cfcfcf"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 2V6"
              stroke="#cfcfcf"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 2V6"
              stroke="#cfcfcf"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="exercise__date-text">{{ item.exercise_dates }}</p>
        </div>
      </div>
    </div>
    <div class="exercise__right-column">
      <div class="exercise__type-visa">
        <p>Тип визы:</p>
        <p>
          <strong>{{ item.visa }}</strong>
        </p>
      </div>
      <div class="marketplace__search exercise__respond">
        <button class="marketplace__search-button">
          <p>Откликнуться</p>
        </button>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: ["item"],
  name: "MarketplaceTask",
};
</script>