<template>
  <article class="marketplace__item">
    <div class="marketplace__item-avatar">
      <picture>
        <img :src="item.avatar" alt="" />
      </picture>
    </div>
    <div class="marketplace__item-info info">
      <div class="info__header">
        <div class="info__header-user user">
          <div class="user__nickname">{{ item.nickname }}</div>
          <div class="user__flex">
            <div class="user__job">{{ item.job }}</div>
            <div class="user__country">{{ item.country }}</div>
          </div>
        </div>
        <div class="info__header-price price">
          <div class="price__per-hour">от {{ item.min_price }} руб./ час</div>
          <div class="price__flex">
            <div class="price__total-fedback">({{ item.feedback_count }})</div>
            <div class="price__stars">
              <picture v-for="i in item.stars" :key="`star-${i}`">
                <img
                  src="img/marketplace/star-marketplace-fill.svg"
                  alt="star"
                />
              </picture>
              <picture v-for="i in 5 - item.stars" :key="`empty-star-${i}`">
                <img
                  src="img/marketplace/star-marketplace-no-fill.svg"
                  alt="star"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div class="info__languages">
        <picture>
          <img src="img/marketplace/open-book.svg" alt="" />
        </picture>
        <p class="info__languages-text">
          {{ item.languages }}
        </p>
      </div>
      <div class="info__text">
        {{ item.info }}
      </div>
      <div class="info__buttons buttons">
        <div class="buttons__tags">
          <a href="#" v-for="tag in item.tags" :key="`tag-${tag.id}`">{{
            tag.name
          }}</a>
        </div>
        <div class="buttons__btns">
          <div class="buttons__btns-camera">
            <picture>
              <img src="/img/marketplace/camera.svg" alt="" />
            </picture>
          </div>
          <div class="buttons__btns-comment">
            <picture>
              <img src="/img/Settings/message.svg" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: ["item"],
  name: "MarketplacePeople",
};
</script>