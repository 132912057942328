<template>
  <main class="tasks">
    <div class="container-1440">
      <div class="tasks__content">
        <h1 class="tasks__title title">Создать задание</h1>
        <form class="tasks__form form" @submit="sendForm">
          <article>
            <Input
              @value-input="saveValue"
              title="Заголовок задания"
              field="title"
              type="text"
              placeholder="Виза в США"
              custom_class="form__email"
              :error="errors.title"
            />
          </article>
          <article>
            <Textarea
              @value-input="saveValue"
              title="Описание"
              placeholder="Введите описание"
              field="text"
              custom_class="form__textarea"
              :error="errors.text"
            />
          </article>
          <article>
            <Input
              @value-input="saveValue"
              title="Стоимость в час"
              field="price_rate"
              type="text"
              placeholder="от 30 до 200"
              custom_class="form__email"
              :error="errors.price_rate"
            />
          </article>
          <article>
            <p class="text form__text">Выберите страну</p>
            <Multiselect
              class="form__select input"
              v-model="values.country_id"
              :options="available_countries"
              :closeOnSelect="true"
            />
          </article>
          <article>
            <p class="text form__text">Выберите тип визы</p>
            <Multiselect
              class="form__select input"
              v-model="values.visa_id"
              :options="available_visas"
              :closeOnSelect="true"
            />
          </article>
          <article>
            <p class="text form__text">Возможные языки консультации</p>
            <Multiselect
              class="form__select input"
              v-model="values.languages_id"
              mode="tags"
              :options="available_languages"
              :closeOnSelect="false"
              :createTag="true"
            />
          </article>
          <article>
            <p class="text form__text">Хэштеги</p>
            <div class="loupe">
              <Multiselect
                ref="tag_selector"
                class="form__select input"
                mode="multiple"
                :hideSelected="false"
                :searchable="true"
                :options="available_tags"
                :multipleLabel="() => {}"
                @search-change="tagChecker"
                @select="addTag"
                @deselect="removeTag"
              />
            </div>
          </article>
          <article>
            <div class="form__tags">
              <div
                class="form__tag"
                v-for="tag in values.tags_id"
                :key="tag.id"
              >
                <svg
                  @click="customRemoveTag(tag.id)"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0569 11.646L12.7021 11.9999L13.0569 12.3539L17.3569 16.6439L17.3579 16.6449C17.4048 16.6914 17.442 16.7467 17.4673 16.8077C17.4927 16.8686 17.5058 16.9339 17.5058 16.9999C17.5058 17.0659 17.4927 17.1313 17.4673 17.1922C17.442 17.2532 17.4048 17.3085 17.3579 17.3549L17.355 17.3578C17.3085 17.4047 17.2532 17.4419 17.1923 17.4673C17.1314 17.4927 17.066 17.5057 17 17.5057C16.934 17.5057 16.8686 17.4927 16.8077 17.4673C16.7468 17.4419 16.6915 17.4047 16.645 17.3578L16.644 17.3568L12.354 13.0568L12 12.702L11.646 13.0568L7.35603 17.3568L7.355 17.3578C7.30852 17.4047 7.25321 17.4419 7.19229 17.4673C7.13136 17.4927 7.06601 17.5057 7 17.5057C6.93399 17.5057 6.86864 17.4927 6.80771 17.4673C6.74679 17.4419 6.69148 17.4047 6.645 17.3578L6.6421 17.3549C6.59524 17.3085 6.55804 17.2532 6.53266 17.1922C6.50727 17.1313 6.4942 17.0659 6.4942 16.9999C6.4942 16.9339 6.50727 16.8686 6.53266 16.8077C6.55804 16.7467 6.59524 16.6914 6.6421 16.6449L6.64314 16.6439L10.9431 12.3539L11.2979 11.9999L10.9431 11.646L6.64355 7.35639C6.6435 7.35633 6.64344 7.35627 6.64338 7.35622C6.54895 7.2617 6.49591 7.13355 6.49591 6.99994C6.49591 6.86625 6.54902 6.73803 6.64355 6.64349C6.73809 6.54896 6.86631 6.49585 7 6.49585C7.13361 6.49585 7.26176 6.54889 7.35628 6.64332C7.35633 6.64338 7.35639 6.64344 7.35645 6.64349L11.646 10.9431L12 11.2979L12.354 10.9431L16.6436 6.64349C16.6437 6.64339 16.6438 6.64329 16.6439 6.64319C16.7384 6.54885 16.8664 6.49585 17 6.49585C17.1337 6.49585 17.2619 6.54896 17.3564 6.64349C17.451 6.73803 17.5041 6.86625 17.5041 6.99994C17.5041 7.13349 17.4511 7.26158 17.3567 7.35609C17.3566 7.35619 17.3565 7.35629 17.3564 7.35639L13.0569 11.646Z"
                    fill="white"
                    stroke="white"
                  />
                </svg>
                <p class="form__tag-text">{{ tag.name }}</p>
              </div>
            </div>
          </article>
          <article class="border-bottom"></article>
          <article>
            <Checkbox
              @value-input="saveValue"
              id="video_consult"
              title="Необходима видеоконсультация"
              field="need_videoconsult"
            />
          </article>
          <article>
            <Checkbox
              @value-input="saveValue"
              id="full_assist"
              title="Необходимо полное сопровождение"
              field="need_fullassist"
            />
          </article>
          <article>
            <Input
              @value-input="saveValue"
              title="Актуально до (дата)"
              field="until_date"
              type="date"
              placeholder="03 December 2021"
              custom_class="form__email input-date"
              :error="errors.until_date"
            />
          </article>
          <article>
            <p class="text form__text">Актуально до (время)</p>
            <Multiselect
              class="form__select input"
              v-model="values.until_time"
              :options="available_time"
              :closeOnSelect="true"
            />
          </article>
          <article>
            <Range
              @value-input="saveValue"
              title="Бюджет"
              addition="$"
              min="0"
              max="100"
              step="1"
              value="50"
              field="budget"
            />
          </article>
          <article>
            <input
              type="submit"
              placeholder=""
              class="form__submit"
              value="Создать задание"
            />
          </article>
        </form>
        <div class="entity__already-login">
          <router-link to="/marketplace" class="entity__already-login-link"
            >Отложить</router-link
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { API } from "../../helpers/api";
import { Validation } from "../../helpers/validation";

import Input from "../../components/utils/Input.vue";
import Textarea from "../../components/utils/Textarea.vue";
import Multiselect from "../../../node_modules/@vueform/multiselect";
import Checkbox from "../../components/utils/Checkbox.vue";
import Range from "../../components/utils/Range.vue";

export default {
  name: "MarketplaceNewTask",
  components: {
    Input,
    Textarea,
    Multiselect,
    Checkbox,
    Range,
  },
  data() {
    return {
      available_countries: [],
      available_visas: [],
      available_languages: [],
      available_tags: [],
      available_time: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      values: {
        title: "",
        text: "",
        price_rate: "",
        country_id: 0,
        visa_id: 0,
        languages_id: [],
        tags_id: [],
        need_videoconsult: false,
        need_fullassist: false,
        until_date: "",
        until_time: "",
        budget: 0,
      },
      errors: {
        title: "",
        text: "",
        price_rate: "",
      },
    };
  },
  async mounted() {
    try {
      const response = await API.send("getCreationData", {}, "GET");

      if (response.status !== 0) {
        alert(response.user_message);
        return;
      }

      this.available_countries  = response.available_countries;
      this.available_visas      = response.available_visas;
      this.available_languages  = response.available_languages;
      this.available_tags       = response.available_tags;
    } catch (e) {
      alert("Произошла неизвестная ошибка. Попробуйте позже.");
      console.error(e);
    }
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm(e) {
      e.preventDefault();
      this.errors.title = Validation.checkText(this.values.title);
      this.errors.text = Validation.checkText(this.values.text);
      this.errors.price_rate = Validation.checkText(this.values.price_rate);

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      const tags_id = {};
      for (const tag of this.values.tags_id) {
        tags_id[tag['id']] = tag['name'];
      }

      const actual_until = (new Date(`${this.values.until_date} ${this.values.until_time}`)).getTime() / 1000;

      const send_data = {
        title: this.values.title,
        text: this.values.text,
        price_rate: this.values.price_rate,
        country_id: this.values.country_id,
        visa_type_id: this.values.visa_id,
        languages_id: this.values.languages_id,
        tags_id: JSON.stringify(tags_id),
        is_need_videoconsultation: +this.values.need_videoconsult,
        is_need_full_follow: +this.values.need_fullassist,
        actual_until,
        budget: this.values.budget,
      }

      try {
        const response = await API.send("createTask", send_data, "POST");

        if (response.status !== 0) {
          alert(response.user_message);
          return;
        }

        alert('Задание успешно создано!');

        this.$router.push(`/marketplace`);
      } catch (e) {
        alert("Неизвестная ошибка. Попробуйте позже.");
      }
    },
    addTag(item) {
      const tag = this.available_tags.find((tag) => tag.value === item);

      this.values.tags_id.push({
        id: tag.value,
        name: tag.label,
      });
    },
    removeTag(id) {
      const tags_array = this.values.tags_id;
      const array_index = tags_array.find((tag) => tag.id === id);

      tags_array.splice(tags_array.indexOf(array_index), 1);

      this.$refs.tag_selector.close();
    },
    customRemoveTag(id) {
      if (!this.available_tags.find((tag) => tag.value === id)) {
        this.removeTag(id);
        return;
      }

      this.$refs.tag_selector.deselect(id);
    },
    tagChecker(query) {
      if (query.includes(",")) {
        query = query.slice(0, query.length - 1);
        this.values.tags_id.push({
          id: query,
          name: query,
        });
        this.$refs.tag_selector.clearSearch();
      }
    },
  },
};
</script>

<style src="../../../node_modules/@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect {
  margin: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/img/arrow.svg) no-repeat right;
  background-position-x: calc(100% - 32px);

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 40px;
}

.multiselect.is-open {
  border-radius: 40px;
}

.multiselect.is-active {
  box-shadow: 0 0 0 3px rgba(1, 167, 253, 0.2);
}

.multiselect-caret {
  display: none;
}

.multiselect-tag,
.multiselect-option.is-selected,
.multiselect-option.is-selected.is-pointed {
  background: #01a7fd;
}

.multiselect-single-label {
  padding-left: 30px;
}

.multiselect-search {
  width: unset;
  position: unset;
}

.form__select.input {
  min-height: 48px;
  height: unset;
}
</style>