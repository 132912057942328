<template>
  <div class="error">
    <p class="text form__text">{{ title }}</p>
    <textarea
      @input="emitter"
      :placeholder="placeholder"
      :class="custom_class"
      class="input"
      v-model="new_value"
    />
    <p class="form__email-error" v-show="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: ["title", "field", "placeholder", "custom_class", "error", "value"],
  data() {
    const already_value = this.value ? this.value : '';
    return {
      new_value: already_value
    };
  },
  methods: {
    emitter() {
      this.$emit("value-input", {
        field: this.field,
        value: this.new_value,
      });
    },
  },
};
</script>

<style>
</style>